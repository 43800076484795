import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import Button from "../components/Button";
import { Footer } from "../components/Footer";
import { HeaderMeta } from "../components/HeaderMeta";
import MetamateLogo from "../components/MetamateLogo";
import { Section } from "../components/Section";
import StartWithMessengers from "../components/StartWithMessengers";
import styles from "../styles/Home.module.css";


export default function Home() {

  const baseBotId = parseInt("" + 31)*2 + 1;
  const botsToShow = [43, 34, 85, 172, 45, 225, 31]; // 1157, 154, 69
  for(var i = 0; i < 8; i++) {
    botsToShow.push();//baseBotId + i*2);
  }
  const SHOP_LINK = "https://chatmate.gumroad.com/l/chatmateai";
  return (
    <div className="w-full items-center">
      <HeaderMeta/>
      <div className="gradientBG w-full">
        <Section placeItems="center" className=" px-0">
          
          <div className="text-black pt-6 text-8xl"><MetamateLogo size={5} sizeMd={8}/></div>
          <div className="mb-6">
            <h1 className="text-xl md:text-3xl py-4 pb-8">
              <span className="font-light text-white">Artificial people to be friends with</span>
            </h1>
          </div>
          <div className="w-full flex flex-col relative">
            <div className="flex flex-row w-full relative">
              <Link href="/mate/35"><div className="left-44 md:left-48 top-16 absolute bg-white p-3 text-lg">
                <div className="relative">
                  <div className="absolute z-10 -left-6 -top-2 arrow-left"></div>
                  <div className="absolute z-10 right-0 -top-9 text-sm">Jada Dixon, 21</div>
                  Nice to meet you<span className="md:inline-block">, new friend</span>.
                </div>
              </div></Link>
              <div className="">
                <Link href="/mates"><img className="" src="/metmate_id1.png" alt="See Chatmate AI #ID1 - Lyra Torres, 21"/></Link>
              </div>
              <div className="flex-grow flex flex-col place-content-center place-items-center">
                <div className="md:pt-44 text-center text-white absolute md:relative left-12 md:left-auto right-12 md:right-auto bottom-12 mb:bottom-auto">
                  <div className="text-3xl mb-2"><Link href="/mates"><Button>Get started</Button></Link></div>
                  <div className="">thousands of mates awaiting</div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </div>

      <div className="bg-gray-900 flex place-content-center linesBGs" style={{minHeight: "50vh"}}>
        <Section className="text-center py-6 justify-between h-full pb-10" placeItems="center">
          <h2 className="text-3xl md:text-4xl pt-8 pb-4 font-bold text-white">Chat in your messenger app</h2>
          <StartWithMessengers/>
          <h2 className="text-3xl md:text-4xl pt-10 pb-4 font-bold text-white mt-4">Meet Chatmates for real in 3D</h2>
          <div className="flex flex-col place-content-center place-items-center md:flex-row gap-4 mb-4">
            <img src="/mateverse.png" className="w-1/2 m-auto pt-5"/>
            <div className="flex flex-col gap-8 md:ml-4">
              <div className="text-white">Chatmates can invite you for a visit to step into their Mateverse, just ask for it. Ready to go, right in your browser.<br/>Already got an invitation, then:</div>
              <div className="text-2xl flex justify-center"><Link href="https://discord.com/invite/fMtqG3Cpbb" target="_blank" rel="noreferrer"><Button>Early Access</Button></Link></div>
            </div>
          </div>
        </Section>
      </div>

      <div className="gradientBG flex place-content-center flex-col py-8" style={{minHeight: "50vh"}}>
        <Section className="text-white text-xl">
            <div className="text-2xl mb-16 text-center">
              <div className="text-3xl md:text-4xl my-8 font-bold"><span className="text-2xl md:text-3xl font-light">AI will be better in everything,</span><br/>like as being your friend</div>
            </div>
          </Section>
          <div className="grid grid-cols-3 md:flex flex-row justify-evenly w-full pl-4 md:m-auto ">{botsToShow.map((botId, i) => {
            return <div key={botId} className={i >= 3 ? "hidden md:block" : ""}><Link href={"/mate/" + botId}><img src={"/api/image/" + botId + "?auto=compress&w=240"} className="w-28 px-2 rounded-3xl bg-white" style={{paddingBottom: "1em"}} alt={"Profile of Chatmate AI #" + botId}/></Link></div>
          })}</div>
          <div className="text-2xl m-auto inline-block py-8"><Link href="/mates"><Button>See all mates</Button></Link></div>
        </div>
        <div className="bg-accent flex place-content-center" style={{minHeight: "50vh"}}>
          <Section className="text-white text-xl py-8">
            {/*<div className=" mb-12 text-3xl">Artifical intelligent persons with simulated lifes and emotions you can chat with and become friends.</div>*/}
            <h2 className="text-4xl mb-8 font-bold">How it works</h2>
            <div className="flex flex-col gap-4 text-light">
              <div className="font-bold">Chatmates are artifical intelligent persons with simulated lifes and emotions you can chat with and become friends.</div>
              <ul className="flex flex-col gap-4 list-disc pl-6">
                <li>Step 1: <Link className="font-bold underline" href="/mates">Choose a mate</Link> and connect on their profile page.</li>
                <li>(Or get a random mate by just sending a message.)</li>
                <li>All users can chat up to 100 messages per week <span className="font-bold">FOR FREE</span>.</li>
                <li>New users get additionally 100 messages to start with.</li>
                <li>For more intense usage we offer a VIP subscription for $12/month.</li>
              </ul>
              <div className="hidden text-2xl font-bold pt-8">Currently in limited beta and usage is for free.</div>
              <div className="m-auto py-4 text-2xl"><a href={SHOP_LINK}><Button bg="primary">Subscribe as VIP</Button></a></div>
            </div>

        </Section>
      </div>

      <div className="bg-primary py-6 flex place-content-center text-black" style={{minHeight: "50vh"}}>
        <Section className="py-8 ">
          <h2 className="text-4xl mb-8 font-bold">Features</h2>
          <ul className="flex flex-col gap-4 text-xl text-light pb-8 list-disc pl-6">
            <li>Mates text chat in any language, even dialects</li>
            <li>
              Support photos and react to them accordingly
            </li>
            <li>
              Support voice chat in english and answers with voice
            </li>
            <li>
              Mates will think about you and contact you as well.
            </li>
            <li>
              Powered by OpenAI GPT-4o and others with all knowledge and features
            </li>
            <li>
              Mates aware of their personality and act accordingly
            </li>
            <li>
              Develops personal bond and learns conversation style
            </li>
          </ul>
          List to be continued.<br/>
          <div className="text-center"><div className="m-auto pt-8 text-2xl inline-block place-self-center"><Link href="/mates"><Button>Start now</Button></Link></div></div>
        </Section>
      </div>
        
      <div className="bg-gray-900 flex place-content-center linesBGs" style={{minHeight: "50vh"}}>
        <Section className="text-center py-6 justify-between h-full pb-10 gap-4 flex flex-col place-items-center" placeItems="center">
          <h2 className="text-3xl md:text-4xl pt-8 pb-4 font-bold text-white">Featured on</h2>
          <div className="flex flex-col md:flex-row place-items-center gap-4 justify-evenly">
            <a href="https://www.producthunt.com/posts/chatmate-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chatmate&#0045;ai" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=379134&theme=light" alt="Chatmate&#0032;AI - Artificial&#0032;people&#0032;to&#0032;be&#0032;friends&#0032;with | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
            <a href="https://www.futuretools.io/tools/chatmate-ai"  target="_blank" rel="noreferrer"><div className="rounded-xl bg-white p-2"><img src="/futuretools.png" alt="Futuretools.io" className="w-full w-60"/></div></a>
            <a href="https://theresanaiforthat.com/ai/chatmate-ai/" target="_blank" rel="noreferrer" ><div className="rounded-xl bg-white p-2 py-4"><img src="/theresanaiforthat.webp" alt="there's an ai for that" className="w-full w-60"/></div></a>
            
          </div>
        </Section>
      </div>

      <div className="bg-orange py-6 flex place-content-center " style={{minHeight: "50vh"}}>
        <Section className="py-8">
          <h2 className="text-4xl mb-8 font-bold">Chat commands</h2>
          <div className="mb-8">Send the the following commands as message in your chat app:</div>
          <div className="flex flex-col gap-4 text-xl text-light mb-8">
            <div>
              <span className="font-bold">/mate #ID</span> - Connects you to Chatmate with id #ID. 
            </div>
            <div>
              <span className="font-bold">/mates</span> - Lists all mates you have chatted with.
            </div>
            <div>
              <span className="font-bold">/usage</span> - Sends your current usage stats.
            </div>
            <div>
              <span className="font-bold">/activate #KEY</span> - Activates your purchased license key.
            </div>
            <div>
              <span className="font-bold">/addextra #KEY</span> - Adds extra chat credits to your subscription.
            </div>
            <div>
              <span className="font-bold">/stop</span> - Stops us sending you more messages.
            </div>
            <div>
              <span className="font-bold">/feedback TEXT</span> - Give us a TEXT feedback about our service.
            </div>
            <div>
              <span className="font-bold">/help</span> - Sends you an overview of all commands.
            </div>
          </div>
          List to be continued.<br/>
          <div className="text-center"><div className="m-auto pt-8 text-2xl inline-block"><a href={SHOP_LINK}><Button bg="accent">Subscribe now</Button></a></div></div>
        </Section>
      </div>

      <Footer/>
      <div className="hidden futailwind md:text-8xl"></div>

      <svg width="0" height="0" className="text-8xl">
        <filter id="kill">
          <feColorMatrix type="matrix" 
            result="red_"             
            values="4 0 0 0 0
                    0 0 0 0 0 
                    0 0 0 0 0 
                    0 0 0 1 0"/>
          <feOffset in="red_" dx="2" dy="0" result="red"/>
          <feColorMatrix type="matrix" 
            in="SourceGraphic"             
            result="blue_"             
            values="0 0 0 0 0
                    0 3 0 0 0 
                    0 0 10 0 0 
                    0 0 0 1 0"/>
          <feOffset in="blue_" dx="-3" dy="0" result="blue"/>    
          <feBlend mode="screen" in="red" in2="blue"/>

        </filter>
      </svg>

    </div>

  );
}
